.form-input-container {
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  width: 100%;
}

.form-input-label-container {
  flex: 1;
}

.form-input-label {
  color: black !important;
}

.form-input {
  flex: 2;

  &:valid + fieldset {
    border: 2px solid green;
  }
}

input[type="date"]::-webkit-calendar-picker-indicator {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: auto;
  height: auto;
  color: transparent;
  background: transparent;
}

.MuiFormHelperText-contained {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.MuiFormHelperText-root.Mui-error {
  color: #b00020 !important;
}
