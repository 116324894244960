.open-space-card {
  background-color: lightgrey !important;
  height: 100%;
}

.open-space-card__item {
  margin-bottom: 5px;
}

.open-space-card__actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.open-space-number {
  color: #212121 !important;
  font-weight: bold !important;
  text-transform: uppercase;
  letter-spacing: 1.5px !important;
  font-size: 10px !important;
}

.open-space-name {
  font-weight: bold !important;
  font-size: 20px !important;
  letter-spacing: 0.5px !important;
}

.open-space-description {
  font-size: 14px;
  color: gray;
}

.open-space-title-container {
  display: flex;
}

.open-space-edit-icon {
  font-size: 14px !important;
}