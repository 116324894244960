.pagination-actions-icon {
  font-size: 18px;
  color: black;
}

.pagination-actions {
  display: flex;
}

.pagination-actions-icon--is-disabled {
  color: rgba(0,0,0, 0.5)
}