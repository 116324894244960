button.custom-google-sign-in {
  font-size: 1.1rem;
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.54);
  
  background-color: white;
  border-radius: 2px;
  border: 1px solid transparent;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 2px 2px 0px, rgba(0, 0, 0, 0.24) 0px 0px 1px 0px;
  transition: opacity 0.35s ease-in;

  width: 220px;
  height: 50px;

  display: inline-flex;
  align-items: center;
  justify-content: space-around;

  &:hover {
    cursor: pointer;
    opacity: 0.80;
  }
}