.modal-gallery-opener {
  background-color: #d7d7d7 !important;
  border: none !important;
  padding: 0 !important;
  min-width: 40px !important;
}

.modal-gallery-pictures {
  display: flex;
  overflow-x: auto;
  padding-inline-start: 0;
}

.modal-gallery-pictures__image {
  height: 130px;
  width: 130px;
  object-fit: cover;

  &:hover {
    opacity: 1;
  }
}

.modal-gallery-pictures__item--selected {
  opacity: 1;
  border: 1px solid #666 !important;
}

.modal-gallery-image--selected {
  height: 500px;
  width: 700px;
  object-fit: cover;
}

.modal-gallery-pictures__item {
  list-style: none;
  margin-right: 20px;
  border: 1px solid #dedede;
  padding: 15px 20px;
  border-radius: 6px;
  cursor: pointer;

  &:last-child {
    margin-right: 0;
  }
}

.MuiDialog-paper {
  overflow: hidden !important;
}

.MuiDialogContent-root {
  overflow-y: hidden !important;
}