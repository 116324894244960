.category-card__content {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.category-card__image {
  height: 150px;
}

.category-table-thumbnail {
  height: 50px;
  width: 50px;
  border-radius: 4px;
}
