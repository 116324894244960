.header-toolbar {
  display: flex;
  justify-content: flex-start;
}

.header-icon {
  font-size: 20px;
  margin-right: 5px;
  color: #666
}

.header-toolbar__title {
  background-color: #cccccc;
  padding: 0 40px !important;

  h2 {
    font-weight: 800 !important;
    font-size: 1.5em;
  }
}

.header-link-container {
  padding: 18px 10px 0 10px;
  cursor: pointer;

  & > a, & > span {
    text-decoration: none;
    font-size: 16px;
    font-family: Roboto, Light;
    color: #666666;
  }

  &:hover {
    border-bottom: 3px solid #666;
  }

  &.header-link-container__active {
    border-bottom: 3px solid black;

    a, span, .header-icon {
      color: black;
    }

    &:hover {
      border-bottom: 3px solid black;
    }
  }
}

.header-links {
  margin-left: 10px;
  display: flex;
}

@media (max-width: 1280px) {
  .header-toolbar > .header-toolbar__content {
    flex: 2;
  }
}

@media (min-width: 792px) {
  .header-toolbar__menu-opener {
    display: none;
  }
}

@media (min-width: 792px) and (max-width: 1279px) {
  .header-toolbar > .header-toolbar__content {
    flex: 2;
  }
}

@media (max-width: 791px) {
  .header-toolbar > .header-toolbar__content {
    display: none;
  }

  .header-toolbar__menu-opener {
    display: block;
  }
}

.header-links--right-side {
  justify-content: flex-end;
  flex: 1;
  align-items: center;
}