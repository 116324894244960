.table-layout {
  background-color: white;
}

.table-heading-cell {
  font-weight: 700 !important;
  letter-spacing: 0.3px;
  font-size: 0.95rem !important;
}

.MuiTableCell-root {
  padding: 0 !important;
}

@media (min-width: 1570px) {
  .MuiIconButton-root {
    padding: 5px !important;
  }
}

.table-text {
  font-size: 0.85rem !important;
  letter-spacing: 0.3px;
}

.table-heading-sort-icon {
  color: #666;
  margin: 0 5px;
  font-size: 18px;
}

.table-layout-cell {
  padding: 6px 10px !important;
}

.table-layout-row {
  &:hover {
    background: #f5f5f5 !important;
  }
}

.MuiTableCell-head {
  font-weight: bold !important;
  font-size: 0.9rem !important;
}

.table-column-clickable {
  cursor: pointer;
}

td,
.MuiTableCell-root,
.MuiTableCell-body,
.table-layout-cell,
.MuiTableCell-alignLeft {
  width: 85px !important;
  font-size: 13px !important;
}

.table-layout-cell__actions {
  display: flex !important;
  justify-content: space-around;
}

.checkBoxTable {
  width: 10px !important;
}

.actionsItems {
  text-align: center !important;
}

@media (max-width: 760px) {
  .actionsItems {
    text-align: start !important;
  }
}
