.date-picker-wrapper {
  border: 1px solid #a4a4a4 !important;
  color: black !important;
  width: 100%;
  border-radius: 4px !important;
  position: relative;

  &:focus,
  &:hover {
    border-color: black;
  }

  .ant-picker-input > input::placeholder,
  .ant-picker-input-placeholder {
    color: #202020 !important;
    font-size: 13px;
  }
}

.ant-picker-focused,
.ant-picker:hover {
  border-color: black !important;
  box-shadow: none !important;
}

.ant-picker-suffix {
  span.icon {
    font-size: 18px;
    color: black;
  }
}

.ant-picker-clear {
  margin-right: 25px;
  font-size: 20px;
  vertical-align: middle;
  margin-top: 2px;

  &::before {
    content: "\f00d";
    display: inline-block;
    font-family: "FAPro";
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    font-weight: 300;
    color: black;
  }

  span.anticon-close-circle {
    display: none;
  }
}

.ant-picker-cell-selected > .ant-picker-cell-inner {
  background-color: #673ab7 !important;
}

.ant-picker-cell-today .ant-picker-cell-inner::before {
  border: 1px solid #673ab7 !important;
}

.ant-picker-today-btn {
  color: #673ab7 !important;
  text-transform: uppercase;
  font-weight: bold;
}

.ant-picker-header button {
  text-transform: uppercase;
  font-weight: bold !important;
}

.ant-picker-header-view button {
  color: #666 !important;

  &:hover {
    color: #673ab7 !important;
  }
}

.ant-picker-content {
  thead > tr > th {
    color: #7c7c7c;
  }
}

.date-picker-wrapper-label {
  position: absolute;
  top: -6px;
  left: 15px;
  background-color: white;
  font-size: 0.895em !important;
  z-index: 2;
  color: #7C7C7C;
}

.ant-picker-range .ant-picker-active-bar {
  background: #673ab7 !important;
}