.add-item-to-group-button {
  background-color: #333 !important;
  color: white !important;
  font-weight: 800 !important;
  text-transform: uppercase;
  letter-spacing: 1.25px;
  line-height: 16px;
  align-self: flex-end;
}

.actions-container {
  margin-top: 20px;
}

.add-field-group-button-container {
  text-align: center;
}

.field-container {
  align-items: center;
}

.form-array-label {
  color: black !important;
}

.field-array-remove-group-button {
  cursor: pointer;
  align-items: center;
}