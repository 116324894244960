.file-input-button {
  background-color: black !important;
  color: white !important;
  font-weight: 800 !important;
  text-transform: uppercase;
  letter-spacing: 1.25px;
  line-height: 16px;
  width: 100%;
}

.file-input-field {
  display: none;
}

.file-input-images-list,
.file-input-container {
  display: flex;
  align-items: center;
}

.file-input-image-preview {
  height: 4em;
  width: 4em;
  object-fit: fill;
  border-radius: 4px;
}

.file-input-image-button {
  height: 1em;
  width: 1em;
  border-radius: 7px;
  background: white !important;
  box-shadow: 0 3px 6px #00000029;
  position: absolute;
  top: -90px;
  left: 30px;

  & > span {
    font-size: 0.6em;
    color: black;
  }
}

.file-image-preview-container {
  position: relative;
}
