.percentage {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  font-family: Roboto;
}

.percentage__amount {
  font-size: 1.38em;
  font-weight: bold;
}

.percentage__description {
  font-size: 1em;
  color: #424242;
}
