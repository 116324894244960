.product-items-table {
  table-layout: fixed;
  width: 100% !important;
}

.item-table-action-icon {
  color: black;
}

.item-product-name {
  font-size: 1.1em !important;
  color: black !important;
}

.item-product-model {
  color: #666 !important;
  font-style: italic !important;
  font-size: 1em !important;
}

.MuiTableCell-stickyHeader {
  top: -38px !important;
}

.table-item-listing__link-item {
  cursor: pointer;
  color: #673ab7;
}

.containerButtons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

@media (max-width: 1500px) {
  .containerCategoryItem {
    flex-direction: column;
  }

  .containerStatusItem {
    flex-direction: column;
  }
}
