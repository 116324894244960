.category-detail-section {
  padding-top: 10px;
}

.category-detail-section--main {
  padding-bottom: 10px;
}

.category-detail-section--currency {
  padding-top: 10px;
  padding-bottom: 5px;

}

.category-detail-section--currency-main {
  padding-bottom: 20px;
}

.category-section-divider {
  padding: 20px 0;

  hr {
    background: #F7F7F7 !important;
  }
}

.item-detail-description {
  color: #525252;
  font-weight: 600 !important;
}

.item-detail-description--secondary {
  color: #525252;
  font-weight: 400 !important;
}

.category-detail-card {
  background-color: #DCDCDC !important;
  box-shadow: none !important;

  .MuiCardContent-root:last-child {
    padding-bottom: 16px !important;
  }
}

.category-changelog-list {
  max-height: 400px;
  overflow-y: auto;
  overflow-x: hidden;
}

.category-detail-section__qr-code {
  cursor: pointer;
  text-align: right;
}