@import '../node_modules/antd/dist/antd.css';

@font-face {
    font-family: "Roboto";
    src: local("Roboto"), url(assets/fonts/Roboto-Regular.ttf) format("ttf");
}

@font-face {
    font-family: "Roboto-Medium";
    src: local("Roboto-Medium"), url(assets/fonts/Roboto-Medium.ttf) format("ttf");
}


@font-face {
    font-family: "Roboto-Bold";
    src: local("Roboto-Bold"), url(assets/fonts/Roboto-Bold.ttf) format("ttf");
}