.screen-layout-header {
  display: flex !important;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.screen-layout-regular {
  padding: 0 0px 20px 5px;
}

.screen-layout-table {
  padding: 0 16px;
}

.screen-layout-content {
  margin-top: 30px;
}

.screen-layout-clean {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  overflow: hidden;
}
