.location-row-racks-header {
  display: flex;
}

.location-rack-header {
  width: 230px;
  background-color: #cccccc !important;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: none !important;
  margin-right: 5px;
}

.location-rack-header--first {
  margin-left: 35px;
}

.location-rack-header-title {
  color: black;
  font-size: 1em !important;
  margin-left: 10px !important;
  align-items: center;
  display: flex;
}

.location-rack-header-button {
  text-transform: none !important;
  color: #666666 !important;
  font-size: 0.87em !important;
}

.location-levels-header {
  display: flex;
  flex-direction: column;
}

.location-levels {
  align-items: center;
}

.location-level-header {
  background-color: #cccccc !important;
  width: fit-content;
  border: none !important;
  box-shadow: none !important;
  margin-right: 5px;
  height: 50px;
  display: flex;
  align-items: center;

  span {
    color: #666666;
    padding: 15px 10px;
    font-weight: bold;
    font-size: 1.2em !important;
  }
}

.location-rack-containers {
  display: flex;
  flex-direction: column;
}

.location-row-rack {
  display: flex;
  flex-direction: column;
}

.location-level {
  &:first-child {
    margin-top: 5px;
  }
}

.location-level,
.location-shelf-container {
  display: flex !important;
}

.location-shelf-container {
  margin-bottom: 5px;
}

.location-shelf {
  width: 230px;
  background-color: #f2f2f2 !important;
  border-radius: 4px;
  margin-right: 5px;
  height: 50px;
  border: none !important;
  box-shadow: none !important;
  display: flex;
  align-items: flex-start;
}

.location-shelf__content {
  padding: 13px 10px;
  color: #666666 !important;
  font-size: 0.875em !important;
  flex: 2;
}

.location-shelf-container--first {
  margin-top: 5px;
}

.location-shelf--empty {
  background-color: transparent !important;
}

.MuiAccordionSummary-content.Mui-expanded,
.MuiAccordionSummary-content.Mui-expanded {
  margin: 0 !important;
}

.location-row-content {
  overflow-x: auto !important;
}

.MuiAccordionSummary-content .Mui-expanded {
  z-index: 0 !important;
}
