.finalPriceIcon {
  color: green !important;
  font-size: 60px;
}
.finalPriceNumber {
  font-weight: bold !important;
  font-size: 60px !important;
  padding-left: 10px !important;
  color: green !important;
}

@media (max-width: 760px) {
  .finalPriceIcon {
    font-size: 30px !important;
  }

  .finalPriceNumber {
    font-size: 30px !important;
  }
}
