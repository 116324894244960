.image-preview-list-image {
  height: 120px;
  width: 120px;
  object-fit: cover;
  cursor: pointer;
}

.image-preview-list-image--main {
  height: 100%;
  width: 100%;
  cursor: normal;
}

.image-preview-list-container {
  padding-bottom: 0 !important;
}
