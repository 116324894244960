.inner-page-section__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.inner-page-section__content {
  margin: 20px 0;
}

.inner-page-section {
  margin-bottom: 20px;
}

.inner-page-section-title {
  font-size: 20px !important;
  font-weight: 600 !important;
}