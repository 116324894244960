.changelog-card {
  background-color: #dcdcdc !important;
  box-shadow: none !important;
  margin-bottom: 10px;

  &:last-child {
    margin-bottom: 0;
  }

  .MuiCardContent-root {
    padding-top: 5px;
  }

  .MuiCardContent-root:last-child {
    padding-bottom: 16px;
  }

  .MuiCardHeader-root {
    padding: 5px 16px 0 16px !important;
  }

}

.changelog-card__header {
  font-size: 12px;
  color: #202020;
  font-style: italic;
}

.changelog-card__description {
  font-size: 14px;
  line-height: 18px;
}
