.date-picker-input {
  padding: 0 !important;
  .ant-picker-input > input::placeholder {
    font-size: 16px !important;
  }

  .ant-picker-input > input {
    font-size: 16px !important;
    padding: 10px !important;
  }

  .ant-picker-focused {
    border-color: #673ab7;
  }

  .ant-picker-suffix {
    margin-right: 10px;
  }
}
