.row-actions-button {
  z-index: 999 !important;
}

.row-actions-container {
  position: absolute;
  width: 100%;
  top: 8px;
  right: 60px;
}
