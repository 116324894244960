.containerCharts {
  display: flex;
  justify-content: center;
  width: 100%;
  flex-direction: row;
  padding-bottom: 30px;

}

.containerChart {
  width: 500px;

  //   border: 1px solid gray;
  padding: 20px;
}

.containSoldAndReadyForSale {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@media (max-width: 760px) {
  .containerCharts {
    display: flex;
    justify-content: center;
    width: 100%;
    align-items: center;
    flex-direction: column;
    padding-bottom: 30px;
  }
  .containerChart {
    width: 350px;
    padding: 20px;
  }
}
