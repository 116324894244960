.form-area-on-demand-button {
  background-color: #333 !important;
  color: white !important;
  font-weight: 800 !important;
  text-transform: uppercase;
  letter-spacing: 1.25px;
  line-height: 16px;
  align-self: flex-end;
}

.form-textarea-on-demand-notes-list {
  margin-top: 10px
}

.form-area-on-demand-button--disabled {
  opacity: 0.5;
}

.form-textarea-field-container {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}