body {
  margin: 0;
  background: #fbfbfb;
  height: 100vh;
}

.app-content {
  display: flex;
  flex: 1;
  margin-top: 100px;
}

.app-grid-container {
  padding: 100px 0 0 0 !important;
  height: 100vh;
  background-color: #fbfbfb;
  overflow-x: auto;
}
