@font-face {
  font-family: "FAPro";
  src: local("FAPro"), url(../../assets/fonts/fa-light-300.woff) format("woff");
}

@font-face {
  font-family: "FAProBrand";
  src: local("FAProBrand"),
    url(../../assets/fonts/fa-brands-400.woff) format("woff");
}

@font-face {
  font-family: "FAProSolid";
  src: local("FAProSolid"),
  url(../../assets/fonts/fa-solid-900.woff) format("woff");
}

.icon::before {
  font-family: "FAPro";
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  font-weight: 300;
}

.icon--brand::before {
  font-family: "FAProBrand";
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  font-weight: 400;
}

.icon--solid::before {
  font-family: "FAProSolid";
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  font-weight: 900;
}

.warehouse::before {
  content: "\f494";
}

.box::before {
  content: "\f466";
}

.home::before {
  content: "\f015";
}

.container::before {
  content: "\f483";
}

.image::before {
  content: "\f03e";
}

.edit::before {
  content: "\f304";
}

.sort-asc::before {
  content: "\f885";
}

.sort-desc::before {
  content: "\f160";
}

.sort-alt::before {
  content: "\f883";
}

.chevron-double-right::before {
  content: "\f324";
}

.chevron-double-left::before {
  content: "\f323";
}

.chevron-right::before {
  content: "\f054";
}

.chevron-left::before {
  content: "\f053";
}

.cancel::before {
  content: "\f00d";
}

.calendar::before {
  content: "\f073";
}

.cancel-circle::before {
  content: "\f057";
}

.ebay::before {
  content: "\f4f4";
}

.globe::before {
  content: "\f0ac";
}

.user-tie::before {
  content: "\f508";
}

.trash-can::before {
  content: "\f2ed";
}

.plus::before {
  content: "\f067";
}

.info::before {
  content: "\f05a";
}

.check::before {
  content: "\f00c";
}

.logout::before {
  content: "\f2f5";
}

.users::before {
  content: "\f500";
}

.qr-code::before {
  content: "\f029";
}

.history::before {
  content: "\f1da";
}

.file::before {
  content: "\f15c";
}

.more-vertical::before {
  content: "\f142";
}

.eye::before {
  content: "\f06e";
}

.dropdown-arrow::before {
  content: "\f0d7";
}

.clone::before {
  content: "\f24d";
}

.list::before {
  content: "\f022"
}

.grid::before {
  content: "\f58d"
}

.rotate-right::before {
  content: "\f01e"
}

.rotate-left::before {
  content: "\f0e2"
}
