.form-step-icon-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.form-step-icon__icon-container {
  padding: 1px 9px;
  border-radius: 50px;
  height: 100%;
  background: #9C9C9C;
  margin-bottom: 10px;
}

.form-step-icon__icon-container--is-active {
  background: #673ab7;
}

.form-step-icon__icon-container--has-error {
  background-color: #B00020;
}

.form-step-icon__label--has-error {
  color: #B00020 !important;
  font-weight: bold;
}

.form-step-icon__icon {
  color: white;
  height: 100%;
  text-align: center;
  align-items: center;
}

.form-step-icon__label {
  color: #9C9C9C;
}

.form-step-icon__label--is-active {
  color: black;
  font-weight: bold !important;
}