.item-qr-code-description-text {
  font-size: 14px;
}

.item-qr-code-description-text--name {
  font-weight: bold;
}

.item-qr-code-description {
  margin-top: 20px;
}