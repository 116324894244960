.form-field-wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 1em;
}

.form-field-wrapper__label {
  color: #000000 !important;
}

.form-field-wrapper__label-required, .form-field-wrapper__error-text {
  color: #B00020 !important;
}

.MuiFormLabel-root.Mui-error {
  color: black !important;
}

.MuiOutlinedInput-notchedOutline {
  &:focus {
    border-width: 2px !important;
  }
}

.MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline {
  border-color: #B00020 !important;
}