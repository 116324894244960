.listing-description {
  color: #666 !important;
}

.listing-status {
  font-weight: bold !important;
}

.listing-link {
  a {
    color: #018786 !important;
  }

  color: #018786 !important;
  text-overflow: ellipsis;
  overflow: hidden;
}
