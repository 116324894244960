.item-detail-text-header {
  font-family: Roboto;
  color: #202020;
}

.item-detail-text-header--bold {
  font-weight: 600;
}

.item-detail-text-header--small {
  font-size: 14px;
  line-height: 16px;
}

.item-detail-text-header--large {
  font-size: 24px;
  line-height: 24px;
  letter-spacing: 0.8px;
}

.item-detail-text-header--medium {
  font-size: 20px;
}