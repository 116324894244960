.img-zoom-container {
  position: relative;
  min-height: 400px;

  img {
    text-align: center;
    max-height: 400px;
    max-width: 600px;
  }
}

.img-zoom-lens {
  position: absolute;
  border: 1px solid #d4d4d4;
  /*set the size of the lens:*/
  width: 160px;
  height: 160px;
  pointer-events: none;
  left: 10%;
}

.img-zoom-result {
  border: 1px solid #d4d4d4;
  /*set the size of the result div:*/
  width: 300px;
  height: 300px;
  opacity: 0;
  position: absolute;
  background-repeat: no-repeat;
  left: 100%;
  top: 0;
}