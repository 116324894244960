.accordion-summary-title {
  text-transform: uppercase;
  font-weight: bold !important;
  line-height: 24;
}

.accordion-summary {
  align-items: center;
  //border: 1px solid #707070 !important;
  //border-radius: 4px !important;
}

.accordion-layout {
  background: white;
  box-shadow: none !important;
  margin-bottom: 10px;
  border: 1px solid #707070 !important;
  border-radius: 4px !important;

  &:last-child {
    margin-bottom: 0 !important;
  }
}