.autocomplete-wrapper {
  width: 100%;
  background: transparent;

  .ant-select-selection-search-input::placeholder,
  .ant-select-selection-placeholder {
    color: rgba(0, 0, 0, 0.87) !important;
  }

  .ant-select-selector {
    border-radius: 4px;
    border-color: #bfbfbf !important;
  }

  .ant-select-selector:hover {
    border-color: black !important;
  }

  .ant-select-clear,
  .ant-select-arrow {
    display: flex;
    align-items: center;
  }
}
