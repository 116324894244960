.warehouse-card {
  cursor: pointer;
  border: 1px solid rgba(112,112,112, 0.49) !important;
  height: 100%;

  & > .MuiCardContent-root:last-child {
    padding-top: 16px !important;
  }
}

.warehouse-card__is-main {
  background-color: #ECECEC !important;
}

.warehouse-icon__is-main {
  color: black !important;
}

.warehouse-icon__is-not-main {
  color: #7C7C7C;
}

.warehouse-card__address-title {
  color: #00000099 !important;
  font-size: 0.8em;
}

.warehouse-card__header{
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding-bottom: 16px;
}

.warehouse-card__title {
  padding: 0 !important;
}

.MuiCardContent-root:last-child {
  padding-top: 0 !important;
  padding-bottom: 16px !important;
}

.warehouse-card__number {
  color: #212121 !important;
  text-transform: uppercase;
  font-size: 0.7em !important;
  font-weight: bold !important;
  letter-spacing: 1.5px;
}