.header-actions-list {
  padding-top: 30px;
}

.header-actions-list__item {
  list-style: none;
  padding: 15px;

  &:first-child {
    padding-top: 0
  }

  &:last-child {
    padding-bottom: 0;
  }
}

.header-actions-list__item a {
  text-decoration: none;
  color: gray;
  text-transform: uppercase;

  &:hover {
    text-decoration: underline;
  }
}